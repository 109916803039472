import styles from './SendDetail.module.scss';
import ondoc from '../../assets/ondoc.png';
import mobileMainBanner02 from '../../assets/mobileMainBanner02.jpg';
import { useEffect, useState } from 'react';

// interface IPropsType {
//   closer: React.Dispatch<React.SetStateAction<boolean>>;
// }
// function SendDetail(props: IPropsType) {
function SendDetail(props) {
  const [peopleInfo, setPeopleInfo] = useState([
    { name: '이름1', id: 'aaaa', phone: '010-1111-1111' },
    { name: '이름2', id: 'bbbb', phone: '010-2222-1111' },
    { name: '이름3', id: 'cccc', phone: '010-3333-1111' },
    { name: '이름4', id: 'dddd', phone: '010-4444-1111' },
    { name: '이름5', id: 'eeee', phone: '010-5555-1111' },
    { name: '이름6', id: 'ffff', phone: '010-6666-1111' },
    { name: '이름7', id: '1111', phone: '010-1111-1111' },
    { name: '이름8', id: '2222', phone: '010-2222-1111' },
    { name: '이름9', id: '3333', phone: '010-3333-1111' },
    { name: '이름10', id: '4444', phone: '010-4444-1111' },
  ]);

  useEffect(() => {
    document.body.style.overflow = 'hidden';
    return () => {
      document.body.style.overflow = 'unset';
    };
  }, []);
  return (
    <>
      <div className={styles.container}>
        <div className={styles.closeWrap} onClick={() => props.closer(false)}>
          닫기
        </div>
        <div className={styles.listWrap}>
          <div className={styles.listHeader}>
            <div>
              <p>순번</p>
              <p>1</p>
            </div>
            <div>
              <p>전송날짜</p>
              <p>2023-12-30</p>
            </div>
          </div>
          <div className={styles.listPeople}>
            <p>받은사람(ID) {peopleInfo.length}명</p>
            <div>
              {peopleInfo.map((item, idx) => {
                return <span key={idx}>{item.id}, </span>;
              })}
            </div>
          </div>
          <div className={styles.listMsg}>
            <p>전송 내용</p>
            <div>
              <span>어쩌고</span>
            </div>
          </div>
          <div className={styles.listImg}>
            <p>이미지</p>
            <div>
              <img src={mobileMainBanner02} alt="ondoc" />
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default SendDetail;
