import { useNavigate } from 'react-router-dom';
import styles from './Header.module.scss';
import logo from '../../assets/logo.png';

function Header() {
  const navigate = useNavigate();
  return (
    <>
      <div className={styles.container}>
        <img src={logo} />
        <span>카카오 전송</span>
        <div className={styles.menuWrap}>
          <ul>
            <li>관리자</li>
          </ul>
        </div>
      </div>
    </>
  );
}

export default Header;
