import { useEffect, useRef, useState } from 'react';
import { CSVLink } from 'react-csv';
import styles from './Home.module.scss';
import Select from 'react-select';
import Header from '../../components/Header/Header';
import Footer from '../../components/Footer/Footer';
import { useLocation, useNavigate } from 'react-router-dom';
import 'react-calendar/dist/Calendar.css';
import Calendar from 'react-calendar';
import moment from 'moment';
import axios from 'axios';
import close from '../../assets/close.png';
import dropNavy from '../../assets/dropNavy.png';
import calendar from '../../assets/calendar.png';
import excelIcon from '../../assets/excelIcon.png';
import sendIcon from '../../assets/sendIcon.png';
import fileIcon from '../../assets/fileIcon.png';

let includeOptions = [];
let options = [];
let optionsCopy = [];

const headers = [
  { label: '사이트', key: 'origin' },
  { label: '아이디', key: 'user_id' },
  { label: '연락처', key: 'phone' },
];

function Home() {
  const navigate = useNavigate();
  const location = useLocation();
  const [conditionOpen, setConditionOpen] = useState(false);
  const [resultOpen, setResultOpen] = useState(false);
  const [sendStateOpen, setSendStateOepn] = useState(false);
  const [calendarOpen, setCalendarOpen] = useState(false);
  const [sendTrueCount, setSendTrueCount] = useState(0);
  const [sendFalseCount, setSendFalseCount] = useState(0);
  const [sendText, setSendText] = useState('');
  const [includeSelect, setIncludeSelect] = useState([]);
  const [exceptSelect, setExceptSelect] = useState([]);
  const [peopleList, setPeopleList] = useState([]);
  const [msgState, setMsgState] = useState('');
  const [imgList, setImgList] = useState([]);
  const [imageBinary, setImageBinary] = useState([]);
  const [calendarValue, setCalendarValue] = useState(new Date());
  const [siteCondition, setSiteCondition] = useState('dietdr');
  const [userCondition, setUserCondition] = useState('purchase');
  const [companySubmitSuccess, setCompanySubmitSuccess] = useState(false);
  const [siteResult, setSiteResult] = useState('다이어트 닥터');
  const [userResult, setUserResult] = useState('구매자');
  const [includeResult, setIncudeResult] = useState('');
  const [exceptResult, setExceptResult] = useState('');
  const [calenderResult, setCalendarResult] = useState(new Date());
  const [searchResultMsg, setSearchResultMsg] =
    useState('검색 결과가 없습니다.');
  const [apiHash, setApiHash] = useState('');
  const [apiDate, setApiDate] = useState('');
  let dateString = '';
  let hashedPassword = '';

  useEffect(() => {
    async function getHashCompanyList() {
      await axios
        .get('https://address.book.ondoc.co.kr/hash', {
          headers: {
            Authorization:
              'Basic b25kb2M6b25kb2NrYWthb2FkZHJlc3MyMDI0MDIwMTY3ODk3NTc2',
          },
        })
        .then(async (res) => {
          hashedPassword = res.data[0].hash;
          dateString = res.data[0].date;
          setApiHash(hashedPassword);
          setApiDate(dateString);
          await axios
            .get(
              `https://admin2.ondoc.co.kr/api/exportCompany?r=${hashedPassword}&d=${dateString}`,
            )
            .then((res) => {
              let company = res.data.data;

              options = company.map((item) => ({
                value: item.cp_id,
                label: item.cp_name,
              }));
              optionsCopy = options;
              includeOptions = options;

              setConditionOpen(true);
            })
            .catch((err) => {});
        })
        .catch((err) => console.log(err));
    }

    if (!localStorage.getItem('login')) return navigate('/');

    const local = JSON.parse(localStorage.getItem('login'));
    const now = new Date();
    console.log(local.expirationTime);
    if (now.getTime() > local.expirationTime) {
      localStorage.removeItem('login');
    }
    getHashCompanyList();
  }, []);

  const valueRef = useRef(exceptSelect);
  valueRef.current = exceptSelect;

  const msgChange = (e) => {
    e.preventDefault();
    const msg = e.target.value;
    setMsgState(msg);
  };

  function encodeFileToBase64(file) {
    return new Promise(function (resolve, reject) {
      let fr = new FileReader();
      fr.onload = function () {
        resolve(fr.result);
      };
      fr.onerror = function () {
        reject(fr);
      };
      fr.readAsDataURL(file);
    });
  }

  function deleteUploadImage(idx) {
    for (let i = 0; i <= idx; i++) {
      if (imgList[i] == 'deleted') idx += 1;
      else break;
    }
    let list = [...imgList];
    let binaryList = [...imageBinary];
    if (typeof imageBinary[idx] == 'string') {
      list[idx] = 'deleted';
      binaryList[idx] = 'deleted';
    } else {
      list.splice(idx, 1);
      binaryList.splice(idx, 1);
    }
    setImgList(list);
    setImageBinary(binaryList);
  }

  const fileMultiUpload = (e) => {
    let files = e.currentTarget.files;
    let readers = [];
    if (!files.length) return;
    let binaryList = [];
    for (let i = 0; i < 1 - imageBinary.length; i++) {
      if (!files[i]) break;
      binaryList.push(files[i]);
    }
    setImageBinary([...imageBinary, ...binaryList]);
    for (let i = 0; i < files.length; i++) {
      readers.push(encodeFileToBase64(files[i]));
    }
    Promise.all(readers).then((values) => {
      let list = [];
      if (values.length + imgList.length > 1) {
        alert('최대 1장까지만 첨부 가능합니다.');
      }
      for (let i = 0; i < 1 - imgList.length; i++) {
        if (!values[i]) break;
        list.push(values[i]);
      }
      setImgList([...imgList, ...list]);
    });
  };

  const companySubmit = async (e) => {
    e.preventDefault();

    let formData = new FormData();

    if (peopleList.length !== 0) peopleList.length = 0;
    setSearchResultMsg('검색중...');

    let calendarValueString =
      calendarValue.getFullYear() +
      '-' +
      (calendarValue.getMonth() + 1) +
      '-' +
      calendarValue.getDate();

    formData.append('siteSelect', siteCondition);
    formData.append('purchaseCondition', userCondition);
    formData.append(
      'selectCompany',
      includeSelect.map((item) => item.value).join(','),
    );
    formData.append(
      'exceptCompany',
      exceptSelect.map((item) => item.value).join(','),
    );
    formData.append('date', calendarValueString);

    if (siteCondition === 'all') setSiteResult('전체');
    if (siteCondition === 'ondoc') setSiteResult('온닥');
    if (siteCondition === 'dietdr') setSiteResult('다이어트 닥터');

    if (userCondition === 'all') setUserResult('전체 회원');
    if (userCondition === 'order_all') setUserResult('구매자 + 구매 후 취소');
    if (userCondition === 'purchase') setUserResult('구매자');
    if (userCondition === 'cancel') setUserResult('구매 후 취소');

    setIncudeResult(includeSelect.map((item) => item.label).join(','));
    setExceptResult(exceptSelect.map((item) => item.label).join(','));
    setCalendarResult(calendarValueString);

    let values = formData.values();
    for (const pair of values) {
      console.log(pair);
    }
    await axios
      .get('https://address.book.ondoc.co.kr/hash', {
        headers: {
          Authorization:
            'Basic b25kb2M6b25kb2NrYWthb2FkZHJlc3MyMDI0MDIwMTY3ODk3NTc2',
        },
      })
      .then(async (res) => {
        hashedPassword = res.data[0].hash;
        dateString = res.data[0].date;
        setApiHash(hashedPassword);
        setApiDate(dateString);
        await axios
          .post(
            `https://admin2.ondoc.co.kr/api/exportUser/search?r=${hashedPassword}&d=${dateString}`,
            formData,
            {
              headers: {
                'Content-Type': 'multipart/form-data',
              },
            },
          )
          .then((res) => {
            setPeopleList(res.data.data);
            setResultOpen(true);
            setCompanySubmitSuccess(true);
            setSearchResultMsg('검색 결과가 없습니다.');
          })
          .catch((err) => {
            console.log(err);
          });
      })
      .catch((err) => {});
  };

  const onSubmit = (e) => {
    e.preventDefault();

    if (peopleList.length === 0) return;

    if (
      (msgState === null || msgState === '' || msgState === undefined) &&
      imgList.length === 0
    )
      return;

    let msg = {
      message: msgState,
      targetList: peopleList.map((item) => ({
        phone: item.phone,
        origin: item.origin,
      })),
      eventName: 'sendMessage',
    };

    setSendText(JSON.stringify(msgState));
    if (window.chrome.webview) {
      window.chrome.webview.postMessage(msg);
      window.chrome.webview.addEventListener('message', function (e) {
        var jsonData = JSON.stringify(e.data);
        var parsedData = JSON.parse(jsonData);
        setSendText(parsedData.message);
      });
    }
  };

  return (
    <>
      <Header />
      <div className={styles.container}>
        <div className={styles.wrap}>
          <div className={styles.conditionWrap}>
            <div
              className={styles.titleWrap}
              onClick={() => setConditionOpen((prev) => !prev)}
            >
              <p>업체 검색</p>
              <img
                style={{
                  transform: conditionOpen ? 'rotate(180deg)' : 'unset',
                }}
                src={dropNavy}
                alt={dropNavy}
              />
            </div>
            {conditionOpen && (
              <form onSubmit={companySubmit}>
                <div className={styles.conditionGroupWrap}>
                  <div className={styles.siteSelectWrap}>
                    <label>사이트 선택</label>
                    <div>
                      <label htmlFor="siteAll">
                        <input
                          type="radio"
                          id="siteAll"
                          name="siteSelect"
                          value="all"
                          onClick={() => {
                            setSiteCondition('all');
                          }}
                        />
                        전체
                      </label>
                      <label htmlFor="ondoc">
                        <input
                          type="radio"
                          id="ondoc"
                          name="siteSelect"
                          value="ondoc"
                          onClick={() => {
                            setSiteCondition('ondoc');
                          }}
                        />
                        온닥
                      </label>
                      <label htmlFor="dietdr">
                        <input
                          type="radio"
                          id="dietdr"
                          name="siteSelect"
                          value="dietdr"
                          onClick={() => {
                            setSiteCondition('dietdr');
                          }}
                          defaultChecked
                        />
                        다이어트 닥터
                      </label>
                    </div>
                  </div>
                  <div className={styles.filterWrap}>
                    <label>회원 선택</label>
                    <div>
                      <label htmlFor="all">
                        <input
                          type="radio"
                          id="all"
                          name="filter"
                          value="all"
                          onClick={() => {
                            setUserCondition('all');
                          }}
                        />
                        전체 회원
                      </label>
                      <label htmlFor="purchase_cancel">
                        <input
                          type="radio"
                          id="purchase_cancel"
                          name="filter"
                          value="order_all"
                          onClick={() => {
                            setUserCondition('order_all');
                          }}
                        />
                        구매자 + 구매 후 취소
                      </label>
                      <label htmlFor="purchase">
                        <input
                          type="radio"
                          id="purchase"
                          name="filter"
                          value="purchase"
                          onClick={() => {
                            setUserCondition('purchase');
                          }}
                          defaultChecked
                        />
                        구매자
                      </label>
                      <label htmlFor="cancel">
                        <input
                          type="radio"
                          id="cancel"
                          name="filter"
                          value="cancel"
                          onClick={() => {
                            setUserCondition('cancel');
                          }}
                        />
                        구매 후 취소
                      </label>
                    </div>
                  </div>
                </div>
                <div className={styles.companyDateConditionWrap}>
                  <div className={styles.includeWrap}>
                    <span>업체 목록</span>
                    <div className={styles.selectWrap}>
                      <Select
                        isMulti
                        name="selectCompany"
                        options={includeOptions}
                        onChange={setIncludeSelect}
                        value={includeSelect}
                        components={{ IndicatorSeparator: () => null }}
                        placeholder="업체 검색"
                      />
                    </div>
                  </div>
                  <div className={styles.exceptWrap}>
                    <span>제외 업체</span>
                    <div className={styles.selectWrap}>
                      <Select
                        isMulti
                        name="exceptCompany"
                        options={options}
                        onChange={setExceptSelect}
                        value={exceptSelect}
                        // hideSelectedOptions={false}
                        components={{ IndicatorSeparator: () => null }}
                        placeholder="제외 업체 검색"
                      />
                    </div>
                  </div>
                  <div className={styles.dateWrap}>
                    <span>날짜 검색</span>
                    <div className={styles.dropboxWrap}>
                      <div
                        className={styles.dropbox}
                        onClick={() => setCalendarOpen((prev) => !prev)}
                      >
                        <div>
                          <img src={calendar} />
                          <p>
                            {moment(calendarValue).format('YYYY년 MM월 DD일')} ~
                          </p>
                        </div>
                        <svg
                          height="20"
                          width="20"
                          viewBox="0 0 20 20"
                          aria-hidden="true"
                          focusable="false"
                          className="css-tj5bde-Svg"
                          style={{
                            transform: calendarOpen
                              ? 'rotate(180deg)'
                              : 'unset',
                          }}
                        >
                          <path
                            d="M4.516 7.548c0.436-0.446 1.043-0.481 1.576 0l3.908 3.747 3.908-3.747c0.533-0.481 1.141-0.446 1.574 0 0.436 0.445 0.408 1.197 0 1.615-0.406 0.418-4.695 4.502-4.695 4.502-0.217 0.223-0.502 0.335-0.787 0.335s-0.57-0.112-0.789-0.335c0 0-4.287-4.084-4.695-4.502s-0.436-1.17 0-1.615z"
                            fill="rgb(204,204,204)"
                          ></path>
                        </svg>
                      </div>
                      {calendarOpen && (
                        <Calendar
                          className={styles.calendar}
                          onChange={(value, event) => {
                            setCalendarValue(value);
                            setCalendarOpen(!calendarOpen);
                          }}
                          value={calendarValue}
                          maxDate={new Date()}
                          formatDay={(locale, date) => moment(date).format('D')}
                        ></Calendar>
                      )}
                    </div>
                  </div>
                </div>
                <div className={styles.btnWrap}>
                  <button type="submit">조건 검색</button>
                </div>
              </form>
            )}
          </div>
          <div className={styles.resultWrap}>
            <div
              className={styles.titleWrap}
              onClick={() => setResultOpen((prev) => !prev)}
            >
              <p>검색 결과</p>
              <img
                style={{
                  transform: resultOpen ? 'rotate(180deg)' : 'unset',
                }}
                src={dropNavy}
                alt={dropNavy}
              />
            </div>
            {resultOpen && companySubmitSuccess && (
              <div className={styles.searchWordWrap}>
                <span>{siteResult}</span>
                <span>{userResult}</span>
                <span>{includeResult ? includeResult : 'X'}</span>
                <span>{exceptResult ? exceptResult : 'X'}</span>
                <span>{calenderResult}</span>
                <span>에 대한 검색 결과</span>
              </div>
            )}
            {resultOpen && peopleList.length === 0 && (
              <div>
                <p>{searchResultMsg}</p>
              </div>
            )}
            {resultOpen && peopleList.length !== 0 && (
              <form onSubmit={onSubmit}>
                {/* 스크롤 api로 받아온 값을 목록에 */}
                <div className={styles.listWrap}>
                  <div className={styles.listTitleWrap}>
                    <div className={styles.listTitle}>
                      <span>회원 목록 </span>
                      <span>({peopleList.length}명)</span>
                    </div>
                    {resultOpen && companySubmitSuccess && (
                      <div className={styles.excelBtnWrap}>
                        <CSVLink
                          headers={headers}
                          data={peopleList}
                          filename="회원 목록.csv"
                          target="_blank"
                          onClick={() => {
                            if (!peopleList.length) return false;
                          }}
                        >
                          <div className={styles.excelBtn}>
                            <img src={excelIcon} />
                            <span>엑셀 다운로드</span>
                          </div>
                        </CSVLink>
                      </div>
                    )}
                  </div>
                  <div className={styles.listScrollHeader}>
                    <ul>
                      <li>순번</li>
                      <li className={styles.listId}>아이디</li>
                      <li>연락처</li>
                    </ul>
                  </div>
                  <div className={styles.listScrollWrap}>
                    {peopleList.map((item, idx) => {
                      return (
                        <ul key={idx}>
                          <li>{idx + 1}</li>
                          <li className={styles.listId}>{item.user_id}</li>
                          <li>{item.phone}</li>
                        </ul>
                      );
                    })}
                  </div>
                </div>
                {peopleList.length !== 0 && (
                  <div>
                    <div className={styles.fileWrap}>
                      <div className={styles.fileTitleWrap}>
                        <span>파일</span>
                        <label>
                          <img src={fileIcon} />
                          파일 첨부
                          <input
                            type="file"
                            multiple
                            name="img"
                            accept=".png, .jpg, .jpeg"
                            onChange={(e) => fileMultiUpload(e)}
                          />
                        </label>
                      </div>
                      <div className={styles.fileCheckWrap}>
                        {imgList
                          .filter((src) => src !== 'deleted')
                          .map((image, idx) => (
                            <div
                              key={idx}
                              style={{
                                borderTopRightRadius: '0px',
                                border:
                                  image.length !== 0 ? '1px solid #ebebeb' : '',
                              }}
                            >
                              <div
                                className={styles.closeBtn}
                                onClick={() => deleteUploadImage(idx)}
                              >
                                <img src={close} alt="close" />
                              </div>
                              <img src={image} alt="selectImg" />
                            </div>
                          ))}
                        {(() => {
                          let list = [];
                          for (
                            let i = 0;
                            i <
                            1 -
                              imgList.filter((src) => src !== 'deleted').length;
                            i++
                          )
                            list.push('');
                          return list.map((item, idx) => <div key={idx}></div>);
                        })()}
                      </div>
                    </div>
                    <div className={styles.msgWrap}>
                      <span>메시지</span>
                      <div className={styles.sendTextWrap}>
                        <textarea
                          className={styles.sendText}
                          name="msg"
                          rows={10}
                          value={msgState}
                          placeholder="메시지를 입력하세요"
                          onChange={(e) => msgChange(e)}
                        />
                        <div>
                          <img src={sendIcon} />
                        </div>
                      </div>
                    </div>
                    <div className={styles.btnWrap}>
                      <button type="submit">카카오 전송</button>
                    </div>
                  </div>
                )}
                <div className={styles.countWrap}>
                  <p>{sendText}</p>
                </div>
                {sendStateOpen && (
                  <div className={styles.countWrap}>
                    <div className={styles.countTrueWrap}>
                      <p>성공:</p>
                      <p>{sendTrueCount}</p>
                      {/* <p>{sendState.current}</p> */}
                    </div>
                    <div className={styles.countFalseWrap}>
                      <p>실패:</p>
                      <p>{sendFalseCount}</p>
                    </div>
                    <div className={styles.countOkWrap}>
                      <p>전송 상태:</p>
                      <p>전송중 | 전송완료</p>
                      <p>{sendTrueCount}/9999</p>
                    </div>
                  </div>
                )}
              </form>
            )}
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
}

export default Home;
