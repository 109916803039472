import styles from './Login.module.scss';
import idImg from '../../assets/id.png';
import pwImg from '../../assets/pw.png';
import logo from '../../assets/logo.png';
import { useNavigate } from 'react-router-dom';
import { useEffect, useState } from 'react';
import axios from 'axios';

function Login() {
  const navigate = useNavigate();
  const [id, setId] = useState('');
  const [pw, setPw] = useState('');

  useEffect(() => {
    if (localStorage.getItem('login')) navigate('/send');
  }, []);

  const idChange = (e) => {
    const id = e.target.value;
    // const regex = /^[a-zA-Z0-9]*$/;
    // if (!regex.test(id)) return;

    setId(id);
  };

  const onSubmit = async (e) => {
    e.preventDefault();
    if (id === '' || id === null || pw === '' || pw === null) return;
    if (id === 'master' && pw === 'onmaster000!') {
      const expirationTime = new Date().getTime() + 24 * 60 * 60 * 1000;
      // const expirationTime = new Date().getTime() + 3000;
      const localDataStore = {
        id,
        expirationTime,
      };
      localStorage.setItem('login', JSON.stringify(localDataStore));
      setTimeout(() => {
        localStorage.removeItem('login');
      }, 24 * 60 * 60 * 1000);
      navigate(`/send`);
    }
  };
  return (
    <>
      <div className={styles.container}>
        <div className={styles.wrap}>
          <div className={styles.titleWrap}>
            <img src={logo} alt="logo" />
            <p>본 페이지는 인증이 필요한 관리자 페이지 입니다.</p>
            <div></div>
            <p>관리자 로그인</p>
          </div>
          <div>
            <form onSubmit={onSubmit}>
              <div className={styles.loginWrap}>
                <div className={styles.inputWrap}>
                  <img src={idImg} alt={idImg} />
                  <input
                    type="text"
                    name="id"
                    value={id}
                    onChange={idChange}
                    placeholder="아이디"
                  />
                </div>
                <div className={styles.inputWrap}>
                  <img src={pwImg} alt={pwImg} />
                  <input
                    type="password"
                    name="pw"
                    value={pw}
                    onChange={(e) => setPw(e.currentTarget.value)}
                    placeholder="비밀번호"
                  />
                </div>
                <button type="submit">로그인</button>
              </div>
            </form>
          </div>
          <div className={styles.bottomWrap}>
            <p>ⓒ ondoc. All Rights Reserved.</p>
          </div>
        </div>
      </div>
    </>
  );
}

export default Login;
