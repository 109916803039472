import { useState } from 'react';
import styles from './SendList.module.scss';
import Header from '../../components/Header/Header';
import TabMenu from '../../components/TabMenu/TabMenu';
import Footer from '../../components/Footer/Footer';
import { useNavigate } from 'react-router-dom';
import SendDetail from '../../components/SendDetail/SendDetail';

function SendList() {
  const navigate = useNavigate();
  const [listModalOpen, setListModalOpen] = useState(false);
  const [list, setList] = useState([
    { msg: '내용', date: '2023-11-20' },
    { msg: '내용', date: '2023-11-21' },
    { msg: '내용', date: '2023-11-22' },
    { msg: '내용', date: '2023-11-23' },
    { msg: '내용', date: '2023-11-24' },
    { msg: '내용', date: '2023-11-25' },
    { msg: '내용', date: '2023-11-26' },
    { msg: '내용', date: '2023-11-27' },
    { msg: '내용', date: '2023-11-28' },
    {
      msg: '내용내용내용내용내용내용내용내용내용...',
      date: '2023-11-29',
    },
  ]);

  const listClick = () => {
    setListModalOpen(!listModalOpen);
  };

  return (
    <>
      <Header />
      <div className={styles.container}>
        <div className={styles.topWrap}>
          <TabMenu />
        </div>
        <div className={styles.listWrap}>
          <div className={styles.listheader}>
            <p>순번</p>
            <p>내용</p>
            <p>전송날짜</p>
          </div>
          <div className={styles.listPageWrap}>
            {list.map((item, idx) => {
              return (
                <ul key={idx} onClick={listClick}>
                  <li>{idx + 1}</li>
                  {item.msg.length > 15 ? (
                    <li>{item.msg.slice(0, 15)}...</li>
                  ) : (
                    <li>{item.msg}</li>
                  )}
                  <li>{item.date}</li>
                </ul>
              );
            })}
          </div>
          <div className={styles.listPage}>페이징 ~</div>
        </div>
      </div>
      <Footer />
      {listModalOpen && <SendDetail closer={setListModalOpen} />}
    </>
  );
}

export default SendList;
