import React from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import Home from './pages/Home/Home';
import SendList from './pages/SendList/SendList';
import Login from './pages/Login/Login';

function App() {
  return (
    <div className="App">
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Login />} />
          <Route path="/send-list" element={<SendList />} />
          <Route path="/send" element={<Home />} />
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;
