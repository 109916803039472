import styles from './Footer.module.scss';

function Footer() {
  return (
    <>
      <div className={styles.container}>© ondoc. All Rights Reserved.</div>
    </>
  );
}

export default Footer;
