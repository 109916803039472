import { useEffect, useState } from 'react';
import styles from './TabMenu.module.scss';
import { useNavigate } from 'react-router-dom';

function TabMenu() {
  const navigate = useNavigate();
  const [path, setPath] = useState('');
  useEffect(() => {
    setPath(window.location.pathname);
  }, []);
  return (
    <>
      <div className={styles.container}>
        <div
          className={path === '/' ? styles.nowTab : styles.tab}
          onClick={() => {
            navigate('/');
          }}
        >
          전송
        </div>
        {/* <div
          className={`${styles.tab} ${
            path === '/send-list' ? styles.nowTab : ''
          }`}
          onClick={() => {
            navigate('/send-list');
          }}
        >
          전송 내역
        </div> */}
      </div>
    </>
  );
}
export default TabMenu;
